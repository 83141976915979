<template>
    <landing :breadCrumbs="breadCrumbs" :shopName="shopName">
        <v-container>
            <v-row
                id="description"
                justify="center"
                :class="smAndUp ? 'mb-15' : null"
            >
                <v-col cols="auto" align="center">
                    <heading><span class="red--text">葛</span>西駅前店</heading>
                </v-col>
            </v-row>

            <!-- 紹介文 -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">アクセス</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >葛西駅メトロセンター出口から徒歩1分!（葛西駅徒歩4分、西葛西駅徒歩10分）!</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">住所</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >東京都江戸川区中葛西3丁目14-10</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">営業時間</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平日13:00～22:00/土日10:00～18:00<br />※時間外は応相談</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">定休日</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >なし</span
                        >
                    </div>
                </v-col>
            </v-row>

            <!-- 代表イメージ -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img height="360px" :src="imageMv"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="bottom center"
                            height="360px"
                            :src="shopImage"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col v-if="!smAndUp" cols="12" align="center">
                    <v-icon
                        small
                        color="red darken-2"
                        @click="$vuetify.goTo('#target')"
                        >fas fa-chevron-down</v-icon
                    >
                    駅からのご案内
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 360px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1687331199590!6m8!1m7!1snIkbSBxRK5E3DfRFDHjGxg!2m2!1d35.66391181411171!2d139.8691678785416!3f285.07!4f0.8199999999999932!5f0.7820865974627469"
                            width="100%"
                            height="360"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </v-card>
                </v-col>
            </v-row>

            <!-- 店舗個別メッセージ -->
            <shop-message
                :message="this.message"
                shopImage="static/noimage.jpg"
            />

            <!-- ナビゲーション -->
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <heading
                        ><span id="target" class="red--text">葛西駅</span
                        >からお越しの方</heading
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">1</span>.
                            改札を出て、西口方面のメトロセンター（高架線下の商店街）にいきます。
                        </div></v-card-title
                    >
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessA"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">2</span>.
                            メトロセンターを進んで、高架線下の信号を渡ります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessB"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span
                                ><span class="red--text text-md-h6">3</span>.
                                メトロセンターの突き当たりまで進み、右側の出口を出ます。</span
                            >
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessC"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">4</span>.
                            外に出たら左に曲がり、高架線に沿って真っ直ぐ進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessD"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">5</span>.
                            セブンイレブンが見えたら、隣の隣の建物の1階です。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessE"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">6</span>.
                            木目の外観の店舗がREVOIST葛西駅前店です。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessF"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </landing>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import ShopMessage from '@/components/landing/ShopMessage.vue'
import Landing from '@/views/Landing.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document.querySelector('meta[name="description"]').setAttribute(
            'content',
            `パーソナルジム REVOISTの12号店です！
葛西駅から徒歩4分、西葛西駅から徒歩10分、葛西と西葛西の間の東西線高架線沿いのジムです！
葛西と西葛西の間にお住まいの方にとっては家から近くパーソナルトレーニングに通いやすいです！
"楽しく続ける"をモットーに、丁寧で分かりやすい指導をします。
1人として同じ体や性格の人はいないので、1人1人に合わせたトレーニングメニューを組みます。
運動初心者の方も過去に挫折してしまった方も、寄り添いながらサポートいたしますので、ぜひ一緒に運動を習慣にしていきましょう。
`
        )
    },
    components: {
        Heading,
        FooterContents,
        ShopMessage,
        Landing,
    },
    data() {
        return {
            shopName: '葛西駅前店',
            center: {
                lat: 35.6641793218916,
                lng: 139.86917578368707,
            },
            zoom: 18,
            markers: [
                {
                    position: {
                        lat: 35.6641793218916,
                        lng: 139.86917578368707,
                    },
                    title: '葛西駅前店',
                },
            ],
            message: `パーソナルジム REVOISTの12号店です！<br />
葛西駅から徒歩4分、西葛西駅から徒歩10分、葛西と西葛西の間の東西線高架線沿いのジムです！<br />
葛西と西葛西の間にお住まいの方にとっては家から近くパーソナルトレーニングに通いやすいです！<br />
<br /><br />
楽しく続ける"をモットーに、丁寧で分かりやすい指導をします。同じ体や性格の人はいないので、1人1人のお客様に合わせたメニューで気持ちに寄り添いサポートいたします！無理なく続けることが心と身体の健康に大切です。運動初心者の方も、過去に挫折してしまった方も、ぜひ一緒に楽しく運動習慣に手に入れましょう。
<br />
<br />
葛西駅前店トレーナー：大西梢哉






`,
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '店舗一覧・アクセス',
                    disabled: false,
                    href: 'gyms',
                },
                {
                    text: '葛西駅前店',
                    disabled: true,
                    href: 'access-12',
                },
            ]
        },

        imageMv() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_12/kasaiekimae-6.jpg'
                : 'static/pc_access_12/kasaiekimae-6.jpg'
        },
        accessA() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_12/kasaiekimae-1.jpg'
                : 'static/pc_access_12/kasaiekimae-1.jpg'
        },
        accessB() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_12/kasaiekimae-2.jpg'
                : 'static/pc_access_12/kasaiekimae-2.jpg'
        },
        accessC() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_12/kasaiekimae-3.jpg'
                : 'static/pc_access_12/kasaiekimae-3.jpg'
        },
        accessD() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_12/kasaiekimae-4.jpg'
                : 'static/pc_access_12/kasaiekimae-4.jpg'
        },
        accessE() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_12/kasaiekimae-5.jpg'
                : 'static/pc_access_12/kasaiekimae-5.jpg'
        },
        accessF() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_12/kasaiekimae-6.jpg'
                : 'static/pc_access_12/kasaiekimae-6.jpg'
        },

        shopImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_12/kasaiekimae-hero.jpg'
                : 'static/pc_access_12/kasaiekimae-hero.jpg'
        },
        trainerImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/trainer/kasaiekimae-trainer-2.jpg'
                : 'static/landing/trainer/kasaiekimae-trainer-2.jpg'
        },
    },
}
</script>

<style lang="scss">
.navigation {
    min-height: 3em;
}
</style>
